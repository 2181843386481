<template>
  <div class="content">
    <div class="content__title content__title--party content__title--start">
      <router-link
        :to="{ name: 'profile-personal' }"
        class="button button--light button--no-indent button--small"
      >
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z" fill="#2B93E7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z" fill="#2B93E7"/>
        </svg>
      </router-link>
      <h1 class="title title--big title--theme">Редактирование адресов</h1>
    </div>
    <form @submit.prevent="onSendForm">
      <app-grid>
        <template #item-1>
          <app-form-group label-for="registration_address" label="Адрес регистрации">
            <template #additional>
              <app-checkbox
                v-model="is_registration_foreign"
                label="не в РФ"
                @change="onRegistrationChange"
              />
            </template>
            <template v-if="is_registration_foreign">
              <app-input
                v-model.trim="form.registration_address_text"
                id="registration_address"
                name="registration_address"
                placeholder="Введите адрес регистрации"
              />
            </template>
            <template v-else>
              <v-select
                v-model="form.registration_address"
                :options="registration_address_options"
                :filterable="false"
                :clearable="false"
                @search="onRegistrationAddressSearch"
                label="address"
                class="select"
                placeholder="Выберите адрес регистрации"
              >
                <template slot="open-indicator">
                  <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none">
                    <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </template>
                <template #no-options="{ search, searching }">
                  <div v-if="searching" class="select__noty">
                    По вашему запросу <em>{{ search }}</em> ничего не найдено.
                  </div>
                  <div v-else class="select__noty">
                    Для поиска адреса в справочнике, введите адрес в формате: <span>Ставрополь, ул. Пушкина, д 65в, кв. 1</span> и выберите значение из выпадающего списка.
                  </div>
                </template>
                <template slot="option" slot-scope="option">
                  <div class="select__item">{{ option.address }}</div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="d-center">{{ option.address }}</div>
                </template>
              </v-select>
            </template>
          </app-form-group>
        </template>
        <template #item-2>
          <app-form-group label-for="residence_address" label="Адрес фактического проживания">
            <template #additional>
              <app-cells position="start" :indent="false">
                <app-checkbox
                  v-model="is_residence_foreign"
                  label="не в РФ"
                  @change="onResidenceChange"
                />
                <app-checkbox
                  v-model="is_identify"
                  label="Совпадает с регистрацией"
                />
              </app-cells>
            </template>
            <template v-if="is_residence_foreign">
              <app-input
                id="residence_address"
                name="registration_address"
                placeholder="Введите адрес фактического проживания"
                v-model.trim="form.residence_address_text"
              />
            </template>
            <template v-else>
              <v-select
                v-model="form.residence_address"
                :options="residence_address_options"
                :filterable="false"
                :clearable="false"
                @search="onResidenceAddressSearch"
                label="address"
                class="select"
                placeholder="Выберите адрес фактического проживания"
              >
                <template slot="open-indicator">
                  <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none">
                    <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </template>
                <template #no-options="{ search, searching }">
                  <div v-if="searching" class="select__noty">
                    По вашему запросу <em>{{ search }}</em> ничего не найдено.
                  </div>
                  <div v-else class="select__noty">
                    Для поиска адреса в справочнике, введите адрес в формате: <span>Ставрополь, ул. Пушкина, д 65в, кв. 1</span> и выберите значение из выпадающего списка.
                  </div>
                </template>
                <template slot="option" slot-scope="option">
                  <div class="select__item">{{ option.address }}</div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="d-center">{{ option.address }}</div>
                </template>
              </v-select>
            </template>
          </app-form-group>
        </template>
      </app-grid>
      <app-cells>
        <app-button ref="submit">Сохранить</app-button>
      </app-cells>
    </form>
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'ProfilePersonalAddressEdit',
  data() {
    return {
      form: {
        registration_address: null,
        residence_address: null,
        registration_address_text: '',
        residence_address_text: '',
      },
      registration_address_options: [],
      residence_address_options: [],
      is_registration_foreign: false,
      is_residence_foreign: false,
      is_identify: false
    }
  },
  mounted() {
    this.$store.dispatch('profile/personal/GET_DATA')
      .then(response => {
        this.form.registration_address = response.data.registration_address
        this.form.registration_address_text = response.data.registration_address_text
        this.form.residence_address = response.data.residence_address
        this.form.residence_address_text = response.data.residence_address_text

        if (this.form.registration_address === null && this.form.registration_address_text) {
          this.is_registration_foreign = true
        }
        if (this.form.residence_address === null && this.form.residence_address_text) {
          this.is_residence_foreign = true
        }
        if (this.form.registration_address_text === this.form.residence_address_text && this.form.registration_address_text !== null && this.form.residence_address_text !== null) {
          this.is_identify = true
        }
      })
  },
  watch: {
    is_identify(value) {
      if (value) {
        if (this.is_registration_foreign) this.is_residence_foreign = true
        else this.is_residence_foreign = false
        this.form.residence_address = this.form.registration_address
        this.form.residence_address_text = this.form.registration_address_text
      } else {
        this.form.residence_address = null
        this.form.residence_address_text = ''
      }
    }
  },
  methods: {
    onRegistrationChange() {
      this.is_identify = false
      if (this.is_registration_foreign) {
        this.form.registration_address = null
        this.form.registration_address_text = ''
      }
    },
    onResidenceChange() {
      this.is_identify = false
      if (this.is_residence_foreign) {
        this.form.residence_address = null
        this.form.residence_address_text = ''
      }
    },
    onRegistrationAddressSearch (search, loading) {
      loading(true)
      this.searchRegistration(loading, search, this)
    },
    searchRegistration: debounce((loading, search, vm) => {
      vm.$store.dispatch('search/GET_ADDRESS', {search}).then(response => {
        vm.registration_address_options = response.data
        loading(false)
      })
    }, 350),
    onResidenceAddressSearch (search, loading) {
      loading(true)
      this.searchResidence(loading, search, this)
    },
    searchResidence: debounce((loading, search, vm) => {
      vm.$store.dispatch('search/GET_ADDRESS', {search}).then(response => {
        vm.residence_address_options = response.data
        loading(false)
      })
    }, 350),
    onSendForm() {
      if (this.form.registration_address) this.form.registration_address_text = this.form.registration_address.address
      if (this.form.residence_address) this.form.residence_address_text = this.form.residence_address.address

      this.$refs.submit.preload = true
      this.$store.dispatch('profile/personal/PATCH_DATA', this.form)
        .then(() => {
          this.$refs.submit.preload = false
          this.$router.push({ name: 'profile-personal' })
          this.$notify({
            type: 'success',
            title: 'Уведомление',
            text: 'Данные успешно сохранены'
          })
        })
        .catch(error => {
          this.$refs.submit.preload = false
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                text: error.response.data[key][0]
              })
            }
          }
        })
    },
  }
}
</script>
